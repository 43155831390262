import { FaGithub } from 'react-icons/fa';

const content = {
  title: 'Contact',
  subtitle: 'お問い合わせ',
  links: [
  ],
};

export default content;
