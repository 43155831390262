import { FaGithub } from 'react-icons/fa';

const content = {
  title: 'About us',
  subtitle: '会社概要',
  links: [
//    {
//      to: 'https://github.com/chronisp',
//      text: 'chronisp',
//      Icon: FaGithub,
//      newTab: true,
//    },
//    {
//      to: 'https://github.com/chronisp/gatsby-starter',
//      text: 'Gatsby Starter',
//      Icon: FaGithub,
//      newTab: true,
//    },
  ],
};

export default content;
